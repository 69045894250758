var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        {
          ref: "genInfoForm",
          attrs: {
            model: _vm.info,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "tplCategory" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(" 生成模板 "),
                      ]),
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.tplSelectChange },
                          model: {
                            value: _vm.info.tplCategory,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "tplCategory", $$v)
                            },
                            expression: "info.tplCategory",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "crud" } }, [
                            _vm._v("单表（增删改查）"),
                          ]),
                          _c("a-select-option", { attrs: { value: "tree" } }, [
                            _vm._v("树表（增删改查）"),
                          ]),
                          _c("a-select-option", { attrs: { value: "sub" } }, [
                            _vm._v("主子表（增删改查）"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "packageName" } },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 生成包路径 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " 生成在哪个java包下，例如 com.ruoyi.system "
                                ),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("a-input", {
                        attrs: { placeholder: "请输入生成包路径" },
                        model: {
                          value: _vm.info.packageName,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "packageName", $$v)
                          },
                          expression: "info.packageName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "moduleName" } },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 生成模块名 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" 可理解为子系统名，例如 system "),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("a-input", {
                        attrs: { placeholder: "请输入生成模块名" },
                        model: {
                          value: _vm.info.moduleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "moduleName", $$v)
                          },
                          expression: "info.moduleName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "businessName" } },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 生成业务名 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" 可理解为功能英文名，例如 user "),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("a-input", {
                        attrs: { placeholder: "请输入生成业务名" },
                        model: {
                          value: _vm.info.businessName,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "businessName", $$v)
                          },
                          expression: "info.businessName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "functionName" } },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 生成功能名 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" 用作类描述，例如 用户 "),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("a-input", {
                        attrs: { placeholder: "请输入生成功能名" },
                        model: {
                          value: _vm.info.functionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "functionName", $$v)
                          },
                          expression: "info.functionName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 上级菜单 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" 分配到指定菜单下，例如 系统管理 "),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "tree-data": _vm.menus,
                          "dropdown-style": {
                            maxHeight: "400px",
                            overflow: "auto",
                          },
                          placeholder: "请选择系统菜单",
                          replaceFields: _vm.treeReplaceFields,
                        },
                        model: {
                          value: _vm.info.parentMenuId,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "parentMenuId", $$v)
                          },
                          expression: "info.parentMenuId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 生成代码方式 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " 默认为zip压缩包下载，也可以自定义生成路径 "
                                ),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.info.genType,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "genType", $$v)
                            },
                            expression: "info.genType",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: "0" } }, [
                            _vm._v("zip压缩包"),
                          ]),
                          _c("a-radio", { attrs: { value: "1" } }, [
                            _vm._v("自定义路径"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-col", { attrs: { span: 12 } }),
              _vm.info.genType == "1"
                ? _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: { xs: { span: 6 }, sm: { span: 3 } },
                            wrapperCol: { xs: { span: 42 }, sm: { span: 21 } },
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(" 自定义路径 "),
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " 填写磁盘绝对路径，若不填写，则生成到当前Web项目下 "
                                    ),
                                  ]),
                                  _c("a-icon", {
                                    attrs: { type: "question-circle-o" },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-input",
                            {
                              model: {
                                value: _vm.info.genPath,
                                callback: function ($$v) {
                                  _vm.$set(_vm.info, "genPath", $$v)
                                },
                                expression: "info.genPath",
                              },
                            },
                            [
                              _c(
                                "a-dropdown",
                                { attrs: { slot: "suffix" }, slot: "suffix" },
                                [
                                  _c(
                                    "a-button",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _vm._v(" 最近路径快速选择 "),
                                      _c("a-icon", { attrs: { type: "down" } }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu",
                                    {
                                      attrs: { slot: "overlay" },
                                      slot: "overlay",
                                    },
                                    [
                                      _c(
                                        "a-menu-item",
                                        {
                                          key: "1",
                                          nativeOn: {
                                            click: function ($event) {
                                              _vm.info.genPath = "/"
                                            },
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "user" },
                                          }),
                                          _vm._v("1st menu item "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.info.tplCategory == "tree",
                  expression: "info.tplCategory == 'tree'",
                },
              ],
            },
            [
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v(" 其他信息 "),
              ]),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 树编码字段 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" 树显示的编码字段名， 如：dept_id "),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.info.treeCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "treeCode", $$v)
                            },
                            expression: "info.treeCode",
                          },
                        },
                        _vm._l(_vm.info.columns, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.columnName } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.columnName + "：" + item.columnComment
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 树父编码字段 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " 树显示的父编码字段名， 如：parent_Id "
                                ),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.info.treeParentCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "treeParentCode", $$v)
                            },
                            expression: "info.treeParentCode",
                          },
                        },
                        _vm._l(_vm.info.columns, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.columnName } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.columnName + "：" + item.columnComment
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 树名称字段 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " 树节点的显示名称字段名， 如：dept_name "
                                ),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.info.treeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "treeName", $$v)
                            },
                            expression: "info.treeName",
                          },
                        },
                        _vm._l(_vm.info.columns, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.columnName } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.columnName + "：" + item.columnComment
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.info.tplCategory == "sub",
                  expression: "info.tplCategory == 'sub'",
                },
              ],
            },
            [
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v(" 关联信息 "),
              ]),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 关联子表的表名 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" 关联子表的表名， 如：sys_user "),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.subSelectChange },
                          model: {
                            value: _vm.info.subTableName,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "subTableName", $$v)
                            },
                            expression: "info.subTableName",
                          },
                        },
                        _vm._l(_vm.tables, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.tableName } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.tableName + "：" + item.tableComment
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    [
                      _c(
                        "span",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _vm._v(" 子表关联的外键名 "),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" 子表关联的外键名， 如：user_id "),
                              ]),
                              _c("a-icon", {
                                attrs: { type: "question-circle-o" },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.info.subTableFkName,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "subTableFkName", $$v)
                            },
                            expression: "info.subTableFkName",
                          },
                        },
                        _vm._l(_vm.subColumns, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.columnName } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.columnName + "：" + item.columnComment
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }